<template>
  <div class="culture-wrapper">
    <ControlTableCultureMobile
      v-if="isMobile"
      :table-data="tableData"
      @editRow="handleEdit"
      @deleteRow="handleDelete"
      @selectDropdownMenu="handleTableMenu"
      @selectionChange="handleSelectionChange"
    />

    <div v-else class="control-table control-table-culture">
      <div class="container container--full-screen">
        <TableBody
          :table-data="tableData"
          :table-fields-desktop="
            isStepTerminal ? controlTableStep : controlTableCultureFieldsDesktop
          "
          :table-fields-tablet="
            isStepTerminal ? controlTableStep : controlTableCultureFieldsTablet
          "
          :table-fields-mobile="
            isStepTerminal ? controlTableStep : controlTableCultureFieldsMobile
          "
          :table-fields-small-mobile="
            isStepTerminal ? controlTableStep : controlTableCultureFieldsMobile
          "
          :table-dropdown-menu-list="controlTableCultureDropdownMenuList"
          :is-loading="getIsLoading"
          :default-sort="{ prop: 'tableList', order: 'descending' }"
          :has-icons="!isViewer"
          @editRow="handleEdit"
          @deleteRow="handleDelete"
          @selectDropdownMenu="handleTableMenu"
          @selectionChange="handleSelectionChange"
          @tableSortByKey="handleSort"
        >
          <template #default="slotProps">
            <TableCell
              :row="slotProps.data.row"
              :item="slotProps.data.row[slotProps.data.prop]"
              :prop-key="slotProps.data.prop"
              :status="slotProps.data.row.status"
            />
          </template>
        </TableBody>

        <TableFooter
          :pagination="pagination"
          text-quantity="Всего культур:"
          item-names="культур"
          @page="changePage"
          @size="changeSize"
        />
      </div>
    </div>

    <DialogAddCulture :params="params" />
    <DialogRemoveTableRow :params="params" />
  </div>
</template>

<script>
import {
  CONTROL_TABLE_CULTURE_ADD_CULTURE,
  CONTROL_TABLE_CULTURE_REMOVE_ROW,
} from '@/constants/dialogs'
import {
  FETCH_CULTURES_PAGE,
  GET_CULTURE_FOR_TABLE,
  GET_CULTURE_PAGINATION,
  GET_IS_LOADING_CULTURE,
} from '@/views/control/store/actions'
import { GET_IS_MOBILE, GET_TERMINAL_CURRENT_ID_SLUG } from '@/store/actions'
import { TableBody, TableFooter } from '@/UI/table'
import {
  controlTableAddItem,
  controlTableRemoveSelectedItems,
} from '@/views/control/data/control'
import {
  controlTableCultureDropdownMenuList,
  controlTableCultureFieldsDesktop,
  controlTableCultureFieldsMobile,
  controlTableCultureFieldsTablet,
  controlTableStep,
} from './data/ControlTableCulture'
import { mapActions, mapGetters } from 'vuex'
import ControlTableCultureMobile from './ControlTableCultureMobile'
import DialogAddCulture from './components/dialogs/dialog-add-culture/DialogAddCulture'
import DialogRemoveTableRow from './components/dialogs/dialog-remove-table-row/DialogRemoveTableRow'
import TableCell from './components/TableCell'

export default {
  name: 'ControlTableCulture',
  components: {
    DialogRemoveTableRow,
    DialogAddCulture,
    TableFooter,
    TableBody,
    TableCell,
    ControlTableCultureMobile,
  },
  data() {
    return {
      controlTableCultureFieldsDesktop,
      controlTableCultureFieldsTablet,
      controlTableCultureFieldsMobile,
      controlTableCultureDropdownMenuList,
      controlTableStep,
      multipleSelectionIds: [],
      multipleSelectionNames: [],
      page: 1,
      perPage: 10,
      order: 0,
      sortData: [],
      isStepTerminal: false,
    }
  },
  computed: {
    ...mapGetters({
      terminalSlug: GET_TERMINAL_CURRENT_ID_SLUG,
      tableData: GET_CULTURE_FOR_TABLE,
      pagination: GET_CULTURE_PAGINATION,
      getIsLoading: GET_IS_LOADING_CULTURE,
      isMobile: GET_IS_MOBILE,
    }),
    params() {
      return { page: this.page, 'per-page': this.perPage }
    },
  },
  watch: {
    terminalSlug: {
      handler(value) {
        'step' === value
          ? (this.isStepTerminal = true)
          : (this.isStepTerminal = false)
      },
      deep: true,
      immediate: true,
    },
    getTerminalCurrentId() {
      this.fetchCultures()
    },
  },
  mounted() {
    this.fetchCultures()
  },
  methods: {
    ...mapActions({ culturesFetch: FETCH_CULTURES_PAGE }),
    fetchCultures() {
      this.culturesFetch(this.params)
    },
    changePage(page = 1) {
      this.page = page
      this.fetchCultures()
    },
    changeSize(size = 10) {
      this.perPage = size
      this.fetchCultures()
    },
    handleEdit(row) {
      this.setDialog({
        name: CONTROL_TABLE_CULTURE_ADD_CULTURE,
        visible: true,
        data: row,
      })
    },
    handleDelete({ id, name }) {
      this.setDialog({
        name: CONTROL_TABLE_CULTURE_REMOVE_ROW,
        visible: true,
        data: {
          isMultipleDelete: false,
          id,
          name,
        },
      })
    },
    handleTableMenu(command) {
      if (command === controlTableRemoveSelectedItems) {
        this.setDialog({
          name: CONTROL_TABLE_CULTURE_REMOVE_ROW,
          visible: true,
          data: {
            isMultipleDelete: true,
            names: this.multipleSelectionNames,
            ids: this.multipleSelectionIds,
          },
        })
      } else if (command === controlTableAddItem) {
        this.setDialog({
          name: CONTROL_TABLE_CULTURE_ADD_CULTURE,
          visible: true,
        })
      }
    },
    handleSelectionChange({ ids, names }) {
      this.multipleSelectionIds = ids
      this.multipleSelectionNames = names
    },
    handleSort(sortKey) {
      if (this.order !== 2) {
        this.order++
      } else {
        this.order = 0
      }
      this.sortData = Object.assign([], this.tableData)

      if (this.order !== 0) {
        this.sortData.sort((a, b) => {
          const order = this.order === 1 ? 1 : -1

          if (a[sortKey] < b[sortKey]) {
            return order
          }
          if (a[sortKey] > b[sortKey]) {
            return order * -1
          }
        })
      }
    },
  },
}
</script>

<style lang="sass">
.control-table
  //box-shadow: 0 3px 9px rgba(75, 81, 91, 0.1), 0 1px 9px rgba(0, 0, 0, 0.1)
  overflow: hidden
  margin-top: 20px

  &-culture
    padding-top: 20px
    svg, path
      //fill: #7D90B2
</style>

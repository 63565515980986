var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "culture-wrapper" },
    [
      _vm.isMobile
        ? _c("ControlTableCultureMobile", {
            attrs: { "table-data": _vm.tableData },
            on: {
              editRow: _vm.handleEdit,
              deleteRow: _vm.handleDelete,
              selectDropdownMenu: _vm.handleTableMenu,
              selectionChange: _vm.handleSelectionChange,
            },
          })
        : _c("div", { staticClass: "control-table control-table-culture" }, [
            _c(
              "div",
              { staticClass: "container container--full-screen" },
              [
                _c("TableBody", {
                  attrs: {
                    "table-data": _vm.tableData,
                    "table-fields-desktop": _vm.isStepTerminal
                      ? _vm.controlTableStep
                      : _vm.controlTableCultureFieldsDesktop,
                    "table-fields-tablet": _vm.isStepTerminal
                      ? _vm.controlTableStep
                      : _vm.controlTableCultureFieldsTablet,
                    "table-fields-mobile": _vm.isStepTerminal
                      ? _vm.controlTableStep
                      : _vm.controlTableCultureFieldsMobile,
                    "table-fields-small-mobile": _vm.isStepTerminal
                      ? _vm.controlTableStep
                      : _vm.controlTableCultureFieldsMobile,
                    "table-dropdown-menu-list":
                      _vm.controlTableCultureDropdownMenuList,
                    "is-loading": _vm.getIsLoading,
                    "default-sort": { prop: "tableList", order: "descending" },
                    "has-icons": !_vm.isViewer,
                  },
                  on: {
                    editRow: _vm.handleEdit,
                    deleteRow: _vm.handleDelete,
                    selectDropdownMenu: _vm.handleTableMenu,
                    selectionChange: _vm.handleSelectionChange,
                    tableSortByKey: _vm.handleSort,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (slotProps) {
                        return [
                          _c("TableCell", {
                            attrs: {
                              row: slotProps.data.row,
                              item: slotProps.data.row[slotProps.data.prop],
                              "prop-key": slotProps.data.prop,
                              status: slotProps.data.row.status,
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("TableFooter", {
                  attrs: {
                    pagination: _vm.pagination,
                    "text-quantity": "Всего культур:",
                    "item-names": "культур",
                  },
                  on: { page: _vm.changePage, size: _vm.changeSize },
                }),
              ],
              1
            ),
          ]),
      _c("DialogAddCulture", { attrs: { params: _vm.params } }),
      _c("DialogRemoveTableRow", { attrs: { params: _vm.params } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }